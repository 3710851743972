
window.preloadImg = function(imgs){
    for (let i = 0; i < imgs.length; i++){
        let img = $('body').createElementInside('img').addClass('image-preload').addAttr('src', imgs[i]);
    }
}



window.carousel = function(imgs, target, duration = 10000){

    var tabImg = [];
    for (let i = 0; i < imgs.length; i++){
        let _img = new Image;
        _img.src = imgs[i];
        tabImg.push(_img);
    }

    var img1 = $(target);
    var cl = img1.classList;
    var img2 = img1.createElementAfter('img').addClass('carousel-img-top');
    var index = 0;

    const swapImg = function(){
        if(img2.hasClass('show')){
            img1.src =  imgs[index];
            img2.removeClass('show');
        }else{
            img2.src =  imgs[index];
            img2.addClass('show');
        }
        index ++;
        if(index >= imgs.length) index = 0;
    }

    const trySwapImg = function(){
        if(tabImg[index].complete){
            swapImg();
            setTimeout(trySwapImg, duration);
        }else{
            setTimeout(trySwapImg, 100);
        }
    }
    setTimeout(trySwapImg, duration);
}
