const menuOffCanvas = function(){
    var hamburger = $('.hamburger');
    var menu = $('#main-nav ul');
    var style = getComputedStyle(menu);
    var top = '-' + menu.offsetHeight + 'px';
    if (style.position == 'absolute'){
        menu.style.top = top;
        hamburger.addEvent('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            menu.style.top = 0;
        });
        window.addEventListener('click', function(e){
            menu.style.top = top;
        });
    }
}

window.addEventListener('scroll', function(e){
    if(window.pageYOffset > 120){
        $('.page-header').addClass('scroll');
    }else{
        $('.page-header').removeClass('scroll');
    }
});

window.addEventListener('load', function(e){
    // menu off canvas
    menuOffCanvas();
});

window.addEventListener('resize', function(e){
    // menu off canvas
    menuOffCanvas();
});

//-------------------------------------------------
window.accordeonInit = function(){
    $('.accordeon-header').each(function(el){
        var style = getComputedStyle(el);
        var height = el.offset().height + parseInt(style.marginBottom) + parseInt(style.marginTop);
        el.parentElement.setStyle('height', height  + 'px');
    });
};

window.accordeon = function(e){
    this.toggleClass('open');
    var style = getComputedStyle(this);
    var height = this.offset().height + parseInt(style.marginBottom) + parseInt(style.marginTop);
    if(this.hasClass('open')){
        console.log((this.nextElementSibling.offsetHeight + height) );
        this.parentElement.setStyle('height', (this.nextElementSibling.offsetHeight + height + 10) + 'px');
    }else{
        this.parentElement.setStyle('height', height  + 'px');
    }
};
